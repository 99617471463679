<template>
  <div>
    <h1>欢迎登录，{{ getAppName }}网上订货商城管理后台</h1>
    <h3 class="mt-5">当前时间：{{ getCurrentTime }}</h3>

    <v-row class="mt-5">
      <v-col v-if="$can('analysis_sale_amount', 'analysis')" cols="12" md="4">
        <MonthSalePrice @choiceData="getSaleDate"></MonthSalePrice>
      </v-col>
      <v-col v-if="$can('analysis_current_day_order', 'analysis')" cols="12" md="8">
        <AnalyticsOrderCard></AnalyticsOrderCard>
      </v-col>
    </v-row>
    <v-row v-if="$can('analysis_sale_chart', 'analysis')" class="mt-5">
      <v-col cols="12">
        <SaleChart></SaleChart>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col v-if="$can('analysis_hot_product', 'analysis')" cols="4">
        <HotProduct />
      </v-col>
      <v-col v-if="$can('analysis_hot_category', 'analysis')" cols="8">
        <HotCategory />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" md="12">
        <InventoryStatisticsCard></InventoryStatisticsCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MonthSalePrice from './MonthSalePrice'
import AnalyticsOrderCard from './AnalyticsOrderCard'
import SaleChart from './ApexChartLineChart'
import HotCategory from './HotCategory'
import HotProduct from './HotProduct'
import InventoryStatisticsCard from './InventoryStatisticsCard'

export default {
  components: {
    MonthSalePrice,
    AnalyticsOrderCard,
    SaleChart,
    HotCategory,
    HotProduct,
    InventoryStatisticsCard,
  },
  data: () => ({}),

  computed: {
    getCurrentTime() {
      // 生成当前时间，20xx年03月04日15时01分
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()

      return `${year}年${month}月${day}日${hour}时${minute}分`
    },
    getAppName() {
      let appName = ''
      if (JSON.parse(localStorage.getItem('userInfo'))) {
        appName = JSON.parse(localStorage.getItem('userInfo')).company
      }

      return appName
    },
  },
  mounted() {},
  methods: {
    getSaleDate(saleDate) {},
  },
}
</script>
