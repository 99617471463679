<template>
  <v-card>
    <v-card-title>
      <span>热门商品分类</span>
      <v-spacer></v-spacer>
      <span class="subtitle-2">
        <v-btn-toggle
          v-model="text"
          mandatory
          color="primary"
        >
          <v-btn
            value="week"
            @click="choiceDate('week')"
          >
            近七日
          </v-btn>
          <v-btn
            value="month"
            @click="choiceDate('month')"
          >
            本月
          </v-btn>
          <v-btn
            value="time"
            @click="choiceRangeData"
          >
            时间段
          </v-btn>

        </v-btn-toggle>

        <v-dialog
          v-model="datePicker"
          width="330"
        >
          <v-card>
            <v-card-title>
              选择时间段
            </v-card-title>
            <v-card-text>
              <v-date-picker
                v-model="dates"
                locale="zh-CN"
                range
              ></v-date-picker>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="choiceDate('diy')"
              >
                选择
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </span>
    </v-card-title>
    <v-card-text>
      <h4
        v-if="series.length === 0"
        class="text-center mb-8"
      >
        暂无数据
      </h4>
      <vue-apex-charts
        v-else
        :key="chartKey"
        type="donut"
        height="350"
        :options="chartOptions"
        :series="series"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import themeConfig from '@themeConfig'
import DateUtil from '../../util/DateUtil'
import AnalysisApi from '@/api/analysis'

const $themeColors = themeConfig.themes.light
const chartColors = {
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
}

export default {
  components: {
    VueApexCharts,
  },
  data: () => ({
    text: 'week',
    chartKey: 0,
    dates: [],
    datePicker: false,
    series: [],
    chartOptions: {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: '"Inter", sans-serif',
      },
      colors: [chartColors.donut.series1,
        chartColors.donut.series5,
        chartColors.donut.series3,
        $themeColors.info,
        chartColors.donut.series2],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: '"Inter", sans-serif',
              },
              value: {
                fontSize: '1rem',
                fontFamily: '"Inter", sans-serif',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}个`
                },
              },

              total: {
                show: true,
                fontSize: '1.5rem',
                label: '商品分类',
                formatter() {
                  return '占比'
                },
              },
            },
          },
        },
      },
      labels: [],
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      fontSize: '1.5rem',
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  }),
  mounted() {
    this.choiceDate('week')
  },
  methods: {
    choiceRangeData() {
      this.datePicker = true
    },
    choiceDate(item) {
      const saleDate = {
        startTime: '',
        endTime: '',
      }
      if (item === 'month') {
        saleDate.startTime = DateUtil.getMonthStartTime()
        saleDate.endTime = DateUtil.getMonthEndTime()
      } else if (item === 'week') {
        saleDate.startTime = DateUtil.getWeekStartTime()
        saleDate.endTime = DateUtil.getWeekEndTime()
      } else if (item === 'diy') {
        saleDate.startTime = this.dates[0]
        saleDate.endTime = this.dates[1]
      }

      AnalysisApi.getHotCategory(saleDate).then(res => {
        const { label, value } = res.data.data
        this.series = value
        this.chartOptions.labels = label
        this.chartKey += 1
        this.datePicker = false
      })
    },

  },
}
</script>
