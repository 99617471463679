<template>
  <v-card class="greeting-card">
    <v-row class="ma-0 pa-0">
      <v-col cols="8">
        <v-card-title class="text-no-wrap pt-1 ps-2">
          {{ text === 'month' ? '本月' : '近七日' }}销售额! 🥳
        </v-card-title>
        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
          <div>
            <p class="text-xl font-weight-semibold primary--text mb-2">
              ¥ {{ parseFloat(amount).toFixed(2) }}
            </p>
            <v-btn-toggle
              v-model="text"
              mandatory
              color="primary"
            >
              <v-btn
                value="month"
                @click="choiceData('month')"
              >
                本月
              </v-btn>

              <v-btn
                value="week"
                @click="choiceData('week')"
              >
                近七日
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-card-text>
      </v-col>

      <v-col
        cols="4"
      >
        <div>
          <v-img
            contain
            height="180"
            width="159"
            :src="require(`@/assets/images/misc/triangle-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            class="greeting-card-bg"
          ></v-img>
          <v-img
            contain
            height="108"
            max-width="83"
            class="greeting-card-trophy "
            src="@/assets/images/misc/trophy.png"
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DateUtil from '../../util/DateUtil'
import AnalysisApi from '@/api/analysis'

export default {
  props: {
  },
  data: () => ({
    text: 'month',
    amount: 0,
  }),
  mounted() {
    this.choiceData('month')
  },
  methods: {
    choiceData(item) {
      const saleDate = {
        startTime: '',
        endTime: '',
      }
      if (item === 'month') {
        saleDate.startTime = DateUtil.getMonthStartTime()
        saleDate.endTime = DateUtil.getMonthEndTime()
      } else if (item === 'week') {
        saleDate.startTime = DateUtil.getWeekStartTime()
        saleDate.endTime = DateUtil.getWeekEndTime()
      }
      AnalysisApi.getSaleAmount(saleDate)
        .then(res => {
          this.amount = res.data.data
        })
      this.$emit('choiceData', saleDate)
    },
  },
}
</script>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
