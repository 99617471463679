import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/order/analysis'
export default {

  getSaleAmount(data) {
    return axios.post(`${prefixPath}/getSaleAmount`, data)
  },
  getDateOrderStatus(data) {
    return axios.post(`${prefixPath}/getDateOrderStatus`, data)
  },
  getCurrentDayOrderStatus() {
    return axios.get(`${prefixPath}/getCurrentDayOrderStatus`)
  },
  getHotProduct(data) {
    return axios.post(`${prefixPath}/getHotProduct`, data)
  },
  getHotCategory(data) {
    return axios.post(`${prefixPath}/getHotCategory`, data)
  },
}
