<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">今日订单统计</span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-subtitle class="mb-5 mt-n1">
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <!-- 待确认 -->
        <v-col
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="primary"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiTrendingUp }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              待确认
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ waitConfirmCount }}
            </h3>
          </div>
        </v-col>
        <!-- 待支付 -->
        <v-col
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="success"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              已支付
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ waitPayCount }}
            </h3>
          </div>
        </v-col>
        <!-- 待发货 -->
        <v-col
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="warning"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiLabelOutline }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              已发货
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ waitDeliveryCount }}
            </h3>
          </div>
        </v-col>
        <!-- 已取消 -->
        <v-col
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="info"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              取消
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ cancelCount }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline } from '@mdi/js'
import AnalysisApi from '@/api/analysis'

export default {
  components: {
  },
  data: () => ({
    icons: {
      mdiDotsVertical,
      mdiTrendingUp,
      mdiAccountOutline,
      mdiLabelOutline,
      mdiCurrencyUsd,
    },
    waitConfirmCount: 0,
    waitPayCount: 0,
    waitDeliveryCount: 0,
    cancelCount: 0,
  }),
  mounted() {
    AnalysisApi.getCurrentDayOrderStatus().then(res => {
      this.waitConfirmCount = res.data.data.waitConfirmCount
      this.waitPayCount = res.data.data.waitPayCount
      this.waitDeliveryCount = res.data.data.waitDeliveryCount
      this.cancelCount = res.data.data.cancelCount
    })
  },
  methods: {

  },
}
</script>
