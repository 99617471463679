<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">出入库统计</span>
      <v-spacer></v-spacer>
      <span class="subtitle-2">
        <v-btn-toggle v-model="text" mandatory color="primary">
          <v-btn value="today" @click="choiceDate('today')"> 今日 </v-btn>
          <v-btn value="yesterday" @click="choiceDate('yesterday')"> 昨日 </v-btn>
          <v-btn value="week" @click="choiceDate('week')"> 近七日 </v-btn>
          <v-btn value="time" @click="choiceRangeData"> 时间段 </v-btn>
        </v-btn-toggle>

        <v-dialog v-model="datePicker" width="330">
          <v-card>
            <v-card-title> 选择时间段 </v-card-title>
            <v-card-text>
              <v-date-picker v-model="dates" locale="zh-CN" range></v-date-picker>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="choiceDate('diy')"> 选择 </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </span>
    </v-card-title>

    <v-card-subtitle class="mb-3 mt-n1"> </v-card-subtitle>

    <v-card-text>
      <v-row>
        <!-- 待出库 -->
        <v-col cols="6" md="3" class="d-flex align-center">
          <v-avatar size="50" color="primary" rounded class="elevation-1">
            <v-icon dark color="white" size="35">
              {{ icons.mdiTruckDeliveryOutline }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">待出库</p>
            <h3 class="text-xl font-weight-semibold">
              {{ waitStockOutCount }}
            </h3>
          </div>
        </v-col>
        <!-- 已出库 -->
        <v-col cols="6" md="3" class="d-flex align-center">
          <v-avatar size="50" color="success" rounded class="elevation-1">
            <v-icon dark color="white" size="35">
              {{ icons.mdiTruckFastOutline }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">已出库</p>
            <h3 class="text-xl font-weight-semibold">
              {{ confirmStockOutCount }}
            </h3>
          </div>
        </v-col>
        <!-- 待入库 -->
        <v-col cols="6" md="3" class="d-flex align-center">
          <v-avatar size="50" color="warning" rounded class="elevation-1">
            <v-icon dark color="white" size="35">
              {{ icons.mdiTruck }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">待入库</p>
            <h3 class="text-xl font-weight-semibold">
              {{ waitStockEnterCount }}
            </h3>
          </div>
        </v-col>
        <!-- 已入库 -->
        <v-col cols="6" md="3" class="d-flex align-center">
          <v-avatar size="50" color="info" rounded class="elevation-1">
            <v-icon dark color="white" size="35">
              {{ icons.mdiTruckCheckOutline }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">已入库</p>
            <h3 class="text-xl font-weight-semibold">
              {{ confirmStockEnterCount }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiTruck, mdiTruckCheckOutline, mdiTruckFastOutline, mdiTruckDeliveryOutline } from '@mdi/js'
import StockApi from '@/api/product/stock'
import DateUtil from '../../util/DateUtil'

export default {
  components: {},
  data: () => ({
    icons: {
      mdiTruck,
      mdiTruckCheckOutline,
      mdiTruckFastOutline,
      mdiTruckDeliveryOutline,
    },
    text: 'today',
    datePicker: false,
    dates: [],
    // 待出库数量
    waitStockOutCount: 0,
    // 已出库数量
    confirmStockOutCount: 0,
    // 待入库数量
    waitStockEnterCount: 0,
    // 已入库数量
    confirmStockEnterCount: 0,
  }),
  mounted() {
    this.choiceDate('today')
  },
  methods: {
    choiceRangeData() {
      this.datePicker = true
    },
    choiceDate(item) {
      const saleDate = {
        startTime: '',
        endTime: '',
      }
      if (item === 'today') {
        saleDate.startTime = DateUtil.getTodayStartTime()
        saleDate.endTime = DateUtil.getTodayEndTime()
      } else if (item === 'yesterday') {
        saleDate.startTime = DateUtil.getYesterdayStartTime()
        saleDate.endTime = DateUtil.getYesterdayEndTime()
      } else if (item === 'week') {
        saleDate.startTime = DateUtil.getWeekStartTime()
        saleDate.endTime = DateUtil.getWeekEndTime()
      } else if (item === 'time') {
        saleDate.startTime = this.dates[0]
        saleDate.endTime = this.dates[1]
      }

      StockApi.getStockCountByDate(this.setSaleDate(saleDate.startTime), this.setSaleDate(saleDate.endTime)).then(
        res => {
          if (!!res.data.data) {
            this.waitStockOutCount = res.data.data.waitStockOutCount
            this.confirmStockOutCount = res.data.data.confirmStockOutCount
            this.waitStockEnterCount = res.data.data.waitStockEnterCount
            this.confirmStockEnterCount = res.data.data.confirmStockEnterCount
          }
        },
      )
    },
    // 时间格式转换
    setSaleDate(date) {
      let inputDate = new Date(date)
      let year = inputDate.getFullYear()
      let month = (inputDate.getMonth() + 1).toString().padStart(2, '0')
      let day = inputDate.getDate().toString().padStart(2, '0')
      let hour = inputDate.getHours().toString().padStart(2, '0')
      let minute = inputDate.getMinutes().toString().padStart(2, '0')
      let second = inputDate.getSeconds().toString().padStart(2, '0')

      let startTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`
      return startTime
    },
  },
}
</script>
