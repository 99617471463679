<template>
  <v-card>
    <v-card-title class="align-start">
      <span>热门商品排行</span>

      <v-spacer></v-spacer>
      <span class="subtitle-2">
        <v-btn-toggle
          v-model="text"
          mandatory
          color="primary"
        >
          <v-btn
            value="week"
            @click="choiceDate('week')"
          >
            近七日
          </v-btn>
          <v-btn
            value="month"
            @click="choiceDate('month')"
          >
            本月
          </v-btn>

          <v-btn
            value="time"
            @click="choiceRangeData"
          >
            时间段
          </v-btn>

        </v-btn-toggle>

        <v-dialog
          v-model="datePicker"
          width="330"
        >
          <v-card>
            <v-card-title>
              选择时间段
            </v-card-title>
            <v-card-text>
              <v-date-picker
                v-model="dates"
                locale="zh-CN"
                range
              ></v-date-picker>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="choiceDate('diy')"
              >
                选择
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </span>
    </v-card-title>

    <v-card-text>
      <v-list class="pb-0">
        <v-list-item
          v-for="(data,index) in hotProduct"
          :key="index"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-4':''}`"
        >
          <v-row>
            <v-col cols="auto">
              <v-avatar
                size="33"
                @click.stop="previewImg(data.productImg)"
              >
                <img
                  :src="data.productImg || require('@/assets/images/white.jpeg')"
                  alt="John"
                >
              </v-avatar>
            </v-col>
            <v-col class="pl-0">
              <span class="text-subtitle-2">{{ data.productName }}  </span><br>
              <span
                class="text-caption"
              >{{ data.productSkuTitle }}</span>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>

          <div>
            <h4 class="font-weight-semibold">
              {{ data.productSkuQuantity }}
            </h4>
          </div>
        </v-list-item>

        <v-list-item v-if="hotProduct.length === 0">
          <v-row>
            <v-col
              cols="12"
              class="text-center"
            >
              <span class="text-subtitle-2">暂无数据</span>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import DateUtil from '../../util/DateUtil'
import AnalysisApi from '@/api/analysis'

export default {
  data: () => ({
    text: 'week',
    chartKey: 0,
    dates: [],
    datePicker: false,
    hotProduct: [

    ],
    icons: {
      mdiDotsVertical,
      mdiChevronUp,
      mdiChevronDown,
    },
  }),
  mounted() {
    this.choiceDate('week')
  },
  methods: {
    choiceRangeData() {
      this.datePicker = true
    },
    choiceDate(item) {
      const saleDate = {
        startTime: '',
        endTime: '',
      }
      if (item === 'month') {
        saleDate.startTime = DateUtil.getMonthStartTime()
        saleDate.endTime = DateUtil.getMonthEndTime()
      } else if (item === 'week') {
        saleDate.startTime = DateUtil.getWeekStartTime()
        saleDate.endTime = DateUtil.getWeekEndTime()
      } else if (item === 'diy') {
        saleDate.startTime = this.dates[0]
        saleDate.endTime = this.dates[1]
      }

      AnalysisApi.getHotProduct(saleDate).then(res => {
        this.hotProduct = res.data.data
        this.chartKey += 1
        this.datePicker = false
      })
    },

  },

}
</script>
