<template>
  <v-card>
    <v-card-title>
      <span>销售统计图</span>
      <v-spacer></v-spacer>
      <span class="subtitle-2">
        <v-btn-toggle
          v-model="text"
          mandatory
          color="primary"
        >
          <v-btn
            value="week"
            @click="choiceDate('week')"
          >
            近七日
          </v-btn>
          <v-btn
            value="month"
            @click="choiceDate('month')"
          >
            本月
          </v-btn>

          <v-btn
            value="time"
            @click="choiceRangeData"
          >
            时间段
          </v-btn>

        </v-btn-toggle>

        <v-dialog
          v-model="datePicker"
          width="330"
        >
          <v-card>
            <v-card-title>
              选择时间段
            </v-card-title>
            <v-card-text>
              <v-date-picker
                v-model="dates"
                locale="zh-CN"
                range
              ></v-date-picker>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="choiceDate('diy')"
              >
                选择
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </span>
    </v-card-title>
    <v-card-text>
      <chartjs-component-line-chart
        :key="chartKey"
        :height="400"
        :data="data"
        :options="options"
        :plugins="plugins"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import DateUtil from '../../util/DateUtil'
import ChartjsComponentLineChart from './ChartjsComponentLineChart'
import AnalysisApi from '@/api/analysis'

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    ChartjsComponentLineChart,
  },
  data: () => ({
    text: 'week',
    chartKey: 0,
    dates: [],
    datePicker: false,
    plugins: [
      {
        beforeInit(chart) {
          /* eslint-disable func-names, no-param-reassign */
          chart.legend.afterFit = function () {
            this.height += 20
          }
          /* eslint-enable */
        },
      },
    ],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: false,
      hover: {
        mode: 'label',
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
      },
      layout: {
        padding: {
          top: -15,
          bottom: -25,
          left: -15,
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
            },
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
            },
            ticks: {
              stepSize: 100,
              min: 0,
              max: 1000,
              fontColor: chartColors.labelColor,
            },
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
          },
        ],
      },
      legend: {
        position: 'top',
        align: 'start',
        labels: {
          usePointStyle: true,
          padding: 25,
          boxWidth: 9,
        },
      },
    },
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          label: '待确认订单数',
          borderColor: chartColors.primaryColorShade,
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: chartColors.primaryColorShade,
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: chartColors.primaryColorShade,
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: chartColors.tooltipShadow,
        },
        {
          data: [],
          label: '已支付订单数',
          borderColor: '#56ca00',
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: '#56ca00',
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: '#56ca00',
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: chartColors.tooltipShadow,
        },
        {
          data: [],
          label: '已发货订单数',
          borderColor: '#ffb400',
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: '#ffb400',
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: '#ffb400',
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: chartColors.tooltipShadow,
        },
        {
          data: [],
          label: '取消订单数',
          borderColor: '#16b1ff',
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: '#16b1ff',
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: '#16b1ff',
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: chartColors.tooltipShadow,
        },
      ],
    },
  }),
  mounted() {
    this.choiceDate('week')
  },
  methods: {
    getMax(arr1, arr2, arr3, arr4) {
      const arr = arr1.concat(arr2, arr3, arr4)

      return Math.max.apply(null, arr)
    },
    choiceRangeData() {
      this.datePicker = true
    },
    choiceDate(item) {
      const saleDate = {
        startTime: '',
        endTime: '',
      }
      if (item === 'month') {
        saleDate.startTime = DateUtil.getMonthStartTime()
        saleDate.endTime = DateUtil.getMonthEndTime()
      } else if (item === 'week') {
        saleDate.startTime = DateUtil.getWeekStartTime()
        saleDate.endTime = DateUtil.getWeekEndTime()
      } else if (item === 'diy') {
        saleDate.startTime = this.dates[0]
        saleDate.endTime = this.dates[1]
      }

      AnalysisApi.getDateOrderStatus(saleDate).then(res => {
        const {
          label, needChecks, cancels, pays, deliveries,
        } = res.data.data
        this.data.labels = label
        this.data.datasets[0].data = needChecks
        this.data.datasets[1].data = pays
        this.data.datasets[2].data = deliveries
        this.data.datasets[3].data = cancels
        this.options.scales.yAxes[0].ticks.max = this.getMax(
          needChecks,
          pays,
          deliveries,
          cancels,
        )
        this.chartKey += 1
        this.datePicker = false
      })
    },
    getDatesInRange(startTime, endTime) {
      const startDate = new Date(startTime)
      const endDate = new Date(endTime)
      const datesInRange = []

      // 循环递增日期，直到达到endDate
      const currentDate = startDate
      while (currentDate <= endDate) {
        const month = currentDate.getMonth() + 1 // 月份是从0开始计数的，所以要加1
        const day = currentDate.getDate()
        const formattedDate = `${month}/${day}`
        datesInRange.push(formattedDate)

        // 递增日期
        currentDate.setDate(currentDate.getDate() + 1)
      }

      return datesInRange
    },
  },
}
</script>
