export default {
  // 获取当天时间的开始时间，即yyyy-MM-dd 00:00:00
  getTodayStartTime() {
    const date = new Date()
    date.setHours(0, 0, 0, 0)

    return date
  },

  // 获取当天时间的结束时间，即yyyy-MM-dd 23:59:59
  getTodayEndTime() {
    const date = new Date()
    date.setHours(23, 59, 59, 999)

    return date
  },

  // 获取昨天时间的开始时间，即yyyy-MM-dd 00:00:00
  getYesterdayStartTime() {
    const date = new Date()
    date.setDate(date.getDate() - 1);
    date.setHours(0, 0, 0, 0)

    return date
  },

  // 获取昨天时间的结束时间，即yyyy-MM-dd 23:59:59
  getYesterdayEndTime() {
    const date = new Date()
    date.setDate(date.getDate() - 1);
    date.setHours(23, 59, 59, 999)

    return date
  },


  // 获取当月的开始时间，即yyyy-MM-01 00:00:00
  getMonthStartTime() {
    const date = new Date()
    date.setDate(1)
    date.setHours(0, 0, 0, 0)

    return date
  },

  // 获取当月的结束时间，即yyyy-MM-dd 23:59:59
  getMonthEndTime() {
    const date = new Date()
    date.setMonth(date.getMonth() + 1)
    date.setDate(0)
    date.setHours(23, 59, 59, 999)

    return date
  },

  // 获取近7天的开始时间，即yyyy-MM-dd 00:00:00
  getWeekStartTime() {
    const date = new Date()
    date.setDate(date.getDate() - 6)
    date.setHours(0, 0, 0, 0)

    return date
  },

  // 获取近7天的结束时间，即yyyy-MM-dd 23:59:59
  getWeekEndTime() {
    const date = new Date()
    date.setHours(23, 59, 59, 999)

    return date
  },

}
